.tableContainer{
    border-radius: 20px;
    height: 700px;
    padding: 0 10px 10px 10px;
    overflow: scroll;
}

Table thead{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    background-color: white;
}

/* table {
    table-layout: fixed;
  } */

td,
tr {
    width: 1px;
    white-space: nowrap;
}

